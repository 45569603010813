module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title) var title = '';
;
__p += '\r\n<section class="ny-banner">\r\n  <div class="bg-img">\r\n    <b class="bg-cover" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></b>\r\n  </div>\r\n  <div class="bg-blue">\r\n    <!-- <b class="bg-cover" style="background-image: url(' +
((__t = (require('../../assets/images/banner_blue.jpg'))) == null ? '' : __t) +
');"></b> -->\r\n    <div class="video">\r\n      <video muted autoplay loop poster="" src="https://seawit.oss-cn-beijing.aliyuncs.com/footer_bg.mp4" x5-video-player-type="h5" x5-video-player-fullscreen="true" webkit-playsinline="" playsinline=""></video>\r\n    </div>\r\n  </div>\r\n  <h1 class="title bold container">' +
((__t = ( title )) == null ? '' : __t) +
'</h1>\r\n</section>';

}
return __p
}