
$('[data-scroll]').css('transition','none').addClass('is-inview')
// let contTopArr = []
// $(document).ready(function(){
//   isInviewinit()
// })
// $(document).scroll(function () {
//   isInviewScroll()
// })

// function getcontTopArr(){
//   contTopArr = []
//   $('[data-scroll]').each(function(index){
//     contTopArr.push({
//       'top':$(this).offset().top - $(window).height(),
//       'h':$(this).outerHeight(),
//       'isrepeat': $(this).attr('data-scroll-repeat') == ''? true : false
//     })
//   })
// }
// function isInviewinit(){
//   getcontTopArr()
//   let scroH = $(document).scrollTop();
//   $.each(contTopArr,function(index){
//     if(this.top < scroH){
//       $('[data-scroll]').eq(index).addClass('is-inview')
//     }
//   })
// }
// function isInviewScroll(){
//   let scroH = $(document).scrollTop();
//   let viewH = $(window).height();
//   $.each(contTopArr,function(index){
//     if(this.isrepeat){
//       if(this.top < scroH && this.top + this.h + viewH*(6/5) > scroH){
//         $('[data-scroll]').eq(index).addClass('is-inview')
//       }else{
//         $('[data-scroll]').eq(index).removeClass('is-inview')
//       }
//     }else{
//       if(this.top <= scroH){
//         $('[data-scroll]').eq(index).addClass('is-inview')
//       }else{
//         $('[data-scroll]').eq(index).removeClass('is-inview')
//       }
//     }
//   })
// }

// $(window).resize(function(){
//   getcontTopArr()
// })