import './index.scss'

$(document).scroll(function () {
  
  if($('.ny-banner .bg-img').length > 0){
    var scroH = $(document).scrollTop(); //滚动高度
    $('.ny-banner .bg-img b').css({
      'margin-top': scroH / 3 +'px',
      'margin-left': scroH / 5 +'px',
    } )
  }
});