import './index.scss'

$('[data-open="cmenu"]').on('click', function () {
  if($('header .hamburger').hasClass('active')){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('.header').removeClass('show-menu')
    $('header .menu-mask').fadeOut(200)
    $('body').css({
      'height':'auto',
      'overflow-y':'auto'
    })
  }else{
    $('header .hamburger').addClass('active')
    $('header .mob-header').addClass('show')
    $('.header').addClass('show-menu')
    $('header .menu-mask').fadeIn(200)
    $('body').css({
      'height':'100vh',
      'overflow-y':'hidden'
    })
  }
})

$('.mob-header ul li .sub-nav a').on('click',function(){
  setTimeout(function(){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .menu-mask').fadeOut(200)
  },200)
})

$('.mob-header ul li .title .iconfont').on('click',function(){
  let $parentEl = $(this).parents('li')
  if($parentEl.hasClass('active')){
    $parentEl.removeClass('active')
    $parentEl.find('.sub-nav').slideUp()
  }else{
    $parentEl.addClass('active').siblings().removeClass('active')
    $parentEl.find('.sub-nav').slideDown()
    $parentEl.siblings().find('.sub-nav').slideUp()
  }
})

$('#header-search-open').on('click',function(){
  $('.header').addClass('show-search')
  // $('#header-search-input').focus()
})
$('.header-search .header-search-close').on('click',function(){
  $('.header').removeClass('show-search')
  // $('#header-search-input').blur()
})

$(document).ready(function(){
  $('.mob-header ul li').each(function(index){
    if($(this).find('.sub-nav a').length > 0){
      $(this).find('.title .iconfont').css('display','flex')
    }
  })
})


let beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度
  var footerTop = $('footer').offset().top
  
  if(scroH > footerTop - viewH + 60 + $('footer .backtop').outerHeight()){
    $('footer .backtop,footer .zixun').addClass('abs')
  }else{
    $('footer .backtop,footer .zixun').removeClass('abs')
  }

  if(scroH > 500){
      $('footer .backtop,footer .zixun').addClass('active')
  }else{
      $('footer .backtop,footer .zixun').removeClass('active')
  }

  if(scroH > 50){
    $('.header').addClass('scroll')
  }else{
    $('.header').removeClass('scroll')
  }

  //判断页面是上滚还是下滚
  let delta = scroH - beforeScroH;
  if (delta > 0) {
    if(scroH > 200){
      $('.header').addClass('trans')
    }else{
      $('.header').removeClass('trans')
    }
  }
  if (delta < 0) {
    $('.header').removeClass('trans')
  }

  beforeScroH = scroH;
});


var mouseover_tid = [];
var mouseout_tid = [];
var mouseover_tid3 = [];
var mouseout_tid3 = [];
$(document).ready(function () {
  $("header .header .right .right-cont .nav ul li:not(.active)").each(function (index) {
    $(this).hover(
      // 鼠标进入
      function () {
        var _self = this;
        // 停止卷起事件
        clearTimeout(mouseout_tid[ index ]);
        // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
        mouseover_tid[ index ] = setTimeout(function () {
          $(_self).find('.title span').animate({opacity:"1",bottom:"8%"},300)
        }, 201);
      },
      // 鼠标离开
      function () {
        var _self = this;
        // 停止展开事件
        clearTimeout(mouseover_tid[ index ]);
        // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
        mouseout_tid[ index ] = setTimeout(function () {
          $(_self).find('.title span').animate({opacity:"0",bottom:"-5%"},300).animate({bottom:"22%"},0)
        }, 201);
      });
    });
    $("header .header .right .right-cont .nav ul li").each(function (index) {
      $(this).hover(
        // 鼠标进入
        function () {
          var _self = this;
          // 停止卷起事件
          clearTimeout(mouseout_tid3[ index ]);
          // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
          mouseover_tid3[ index ] = setTimeout(function () {
            $(_self).addClass('hover')
          }, 201);
        },
        // 鼠标离开
        function () {
          var _self = this;
          // 停止展开事件
          clearTimeout(mouseover_tid3[ index ]);
          // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
          mouseout_tid3[ index ] = setTimeout(function () {
            $(_self).removeClass('hover')
          }, 201);
        });
      });
});

var mouseover_tid2 = [];
var mouseout_tid2 = [];
$(document).ready(function () {
  $("header .header .right .right-cont .language").each(function (index) {
    $(this).hover(
      // 鼠标进入
      function () {
        var _self = this;
        // 停止卷起事件
        clearTimeout(mouseout_tid2[ index ]);
        // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
        mouseover_tid2[ index ] = setTimeout(function () {
          let bg_h = $("header .header .right .right-cont .language .language-title").outerHeight() + $("header .header .right .right-cont .language .language-sub").outerHeight()
          $(_self).addClass('active').find('.language-bg').css('height',bg_h + 'px')
        }, 201);
      },
      // 鼠标离开
      function () {
        var _self = this;
        // 停止展开事件
        clearTimeout(mouseover_tid2[ index ]);
        // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
        mouseout_tid2[ index ] = setTimeout(function () {
          $(_self).removeClass('active').find('.language-bg').css('height','100%')
        }, 201);
      });
    });
});